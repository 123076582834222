import {
  ME,
  ACTIVITY,
  APPLICATION,
  ATTENDANCE,
  DASHBOARD,
  LIVESTREAM,
  PRODUCTIVITY,
  REPORTS,
  TIMELINE,
  WELLNESS,
  FIELD,
  PROJECTS,
  TASK,
  SCREENSHOT,
  ALERTS,
  NOTEBOOK,
  SETTINGS,
  ACCOUNT,
  BILLING,
  DOWNLOAD,
  LEAVE_MANAGEMENT,
  DEVICE360,
  MANUALTIME,
  GOALS,
} from "../route/constant";

export const getFeatures = (features) => {
  return {
    MAX_USERS: {
      label:
        features.MAX_USERS.data.endRange !== Number.MAX_SAFE_INTEGER
          ? `Upto ${features.MAX_USERS.data.endRange} Users`
          : "Unlimited Users",
    },
    DATA_RETENTION: {
      label: `Data Retention (${features.DATA_RETENTION.data.value})`,
    },
    CAPTURE_FREQUENCY_LIVE: {
      label: `Capture Freq Live (${features.CAPTURE_FREQUENCY_LIVE.data.value})`,
    },
    DASHBOARD: { label: `Dashboard` },
    ATTENDANCE: { label: `Attendance` },
    ACTIVITY: { label: `Activity` },
    SETTINGS: { label: `Settings` },
    PRODUCTIVITY: { label: `Productivity` },
    APPLICATION: { label: `Application` },
    TIMELINE: { label: `Timeline` },
    LIVESTREAM: { label: `Livestream` },
    WELLNESS: { label: `Wellness` },
    SHIFT: { label: `Shift` },
    SCREENSHOT: { label: `Screenshot` },
    REPORTS: {
      label: features.REPORTS.data ? `Email/Download All Reports` : `Reports`,
    },
    SSO: { label: `SSO` },
    ADMIN_MOBILE_APP: { label: `Admin Mobile App` },
    PROJECT_MANAGEMENT: { label: `Project Management` },
    FIELD: { label: `Field` },
    WHITE_LABELLING: { label: `White Labelling` },

    CHAT: { label: `Chat` },
    ALERTS: { label: `Alerts` },
    INTEGRATIONS: {
      label: `Integrations${
        features.INTEGRATIONS.data
          ? ` (${features.INTEGRATIONS.data.value})`
          : ""
      }`,
    },
    ACTIVE_DIRECTORY: { label: `Active Directory` },
    LOCATION_FILTERING: { label: `Location Filtering` },
    GEO_FENCING: { label: `Geo Fencing` },
    LEAVE_MANAGEMENT: { label: `Leaves` },
    DEVICE: { label: `Devices` },
    MANUALTIME: { label: `Leaves` },
  };
};

export const pathToFeatureKeyMap = {
  [ME]: "DASHBOARD",
  [ACTIVITY]: "ACTIVITY",
  [APPLICATION]: "APPLICATION",
  [ATTENDANCE]: "ATTENDANCE",
  [DASHBOARD]: "DASHBOARD",
  [LIVESTREAM]: "LIVESTREAM",
  [PRODUCTIVITY]: "PRODUCTIVITY",
  [GOALS]: "PRODUCTIVITY",
  [REPORTS]: "REPORTS",
  [TIMELINE]: "TIMELINE",
  [WELLNESS]: "WELLNESS",
  [FIELD]: "FIELD",
  [PROJECTS]: "PROJECT_MANAGEMENT",
  [TASK]: "PROJECT_MANAGEMENT",
  [SCREENSHOT]: "SCREENSHOT",
  [ALERTS]: "ALERTS",
  [NOTEBOOK]: "NOTEBOOK",
  [SETTINGS]: "SETTINGS",
  [ACCOUNT]: "SETTINGS",
  [BILLING]: "SETTINGS",
  [DOWNLOAD]: "SETTINGS",
  [LEAVE_MANAGEMENT]: "LEAVE_MANAGEMENT",
  [DEVICE360]: "DEVICE360",
  [MANUALTIME]: "PROJECT_MANAGEMENT",
  whiteLabelling: "WHITE_LABELLING",
  leaveSetting: "LEAVE_MANAGEMENT",
  compliance: "SETTINGS",
  stealthConfig: "SETTINGS",
  goalSetting: "PRODUCTIVITY",
  break: "SETTINGS",
  users: "SETTINGS",
  designations: "SETTINGS",
  group: "SETTINGS",
  shift: "SHIFT",
  wellness: "WELLNESS",
  productivityRules: "PRODUCTIVITY",
  alertRules: "ALERTS",
  emailReports: "REPORTS",
  alertLogs: "ALERTS",
  workplace: "SETTINGS",
  roles: "SETTINGS",
  integrations: "INTEGRATIONS",
  crossDayAttendance: "CROSS_DAY_ATTENDANCE",
};

export const BILLING_MESSAGES = {
  OWNER: {
    CANCELLED:
      "Your subscription has been cancelled, Please join a plan to continue!",
    EXPIRED: "Your subscription has expired, Please join a plan to continue!",
    OVERDUE: "You have unpaid invoices, Please settle them to continue!",
    PAUSED:
      "Your subscription has been paused, Please contact support to continue!",
    LICENSE_LIMIT_EXCEEDED:
      "You have exceeded your license limit, Please deactivate some users or add more licenses to continue",
  },
  USER: {
    CANCELLED:
      "Your subscription has been cancelled, Please contact your admin!",
    EXPIRED: "Your subscription has expired, Please contact your admin!",
    OVERDUE:
      "Your existing subscription has pasused, Please contact your admin!",
    PAUSED: "Your subscription has been pasused, Please contact your admin!",
    LICENSE_LIMIT_EXCEEDED:
      "Your organization have exceeded their license limit, Please contact your admin!",
  },
};
