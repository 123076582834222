import * as actionTypes from "./actionTypes";

export const fetchIntegrationLogsReport = (payload) => {
  return {
    type: actionTypes.FETCH_INTEGRATION_LOGS_REPORT,
    payload,
  };
};

export const fetchIntegrationLogsReportSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_INTEGRATION_LOGS_REPORT_SUCCESS,
    payload,
  };
};

export const fetchIntegrationLogsReportFailed = (payload) => {
  return {
    type: actionTypes.FETCH_INTEGRATION_LOGS_REPORT_FAILED,
    payload,
  };
};

export const downloadIntegrationLogsReport = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_INTEGRATION_LOGS_REPORT,
    payload,
  };
};

export const downloadIntegrationLogsReportSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_INTEGRATION_LOGS_REPORT_SUCCESS,
    payload,
  };
};

export const downloadIntegrationLogsReportFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_INTEGRATION_LOGS_REPORT_FAILED,
    payload,
  };
};
