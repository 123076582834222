import React, { useState, useContext, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { moduleList } from "constants/constants";
import { openUserDetail } from "redux/store/user-detail/action";
import User from "../../assets/images/userIO.svg";
import dayjs from "dayjs";
import { AuthContext } from "../../contexts/authContext";
import { useDispatch, useSelector } from "react-redux";
import { MappingAction, UserAction } from "redux/store/action";
import { Input, Select } from "antd";
import {
  DesktopOutlined,
  GlobalOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { permissionsMap } from "constants/constant";

const Commonsearch = () => {
  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState("Explorer");
  const { KState, checkRequiredPermissions, getDefaultHeader } =
    useContext(AuthContext);
  const [userNodes, setuserNodes] = useState([]);
  const applicationurl = useSelector(
    // @ts-ignore
    (state) => state.mapping
  );

  const {
    user_list_success,
    user_list_data,
    user_list_failed,
    user_list_processing,
  } = useSelector(
    // @ts-ignore
    (state) => state.users
  );
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionUsers, setSuggestionUsers] = useState([]);
  const [appsUrls, setAppsUrls] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Get the current pathname

    setsearchText("");
    setSuggestionUsers([]);
    setSuggestions([]);
    setAppsUrls([]);
  }, [location.pathname]);

  const getUserTreeNode = (userList) => {
    const ulist = [];
    const uName = [];
    const uIds = [];
    let id = "";
    let fullname = "";
    let email = "";
    userList.forEach((item) => {
      id = item?.identity?.id;
      fullname = item?.identity?.first_name + " " + item?.identity?.last_name;
      email = item?.identity?.email;
      uIds.push(id);
      uName.push(fullname);
      ulist.push({
        title: fullname,
        children: null,
        value: id,
        email: email,
      });
    });

    setuserNodes(ulist);
  };
  const GetUserList = () => {
    if (
      KState &&
      KState.keycloak &&
      !user_list_success &&
      !user_list_processing
    ) {
      const data = {
        headers: getDefaultHeader(),
        isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
      };

      dispatch(UserAction.UserListProcess(data));
    }
  };
  useEffect(() => {
    if (user_list_success === true) {
      if ((user_list_data || []).length > 0) {
        getUserTreeNode(user_list_data);
      } else if (user_list_failed === true) {
        setuserNodes([]);
      }
    }
  }, [user_list_success, user_list_failed, user_list_failed]);

  useEffect(() => {
    GetUserList();
  }, []);
  const getBodyParameters = (mappedType, searchValue, appUrlValue) => {
    let body = {};
    if (mappedType === "all") {
      body =
        appUrlValue === "ALL"
          ? !searchValue
            ? { ilike: {} }
            : { ilike: { value: searchValue } }
          : !searchValue
          ? { ilike: { type: appUrlValue } }
          : { ilike: { value: searchValue, type: appUrlValue } };
    }
    return body;
  };
  const GetApplicationUrlList = (param, body) => {
    if (KState?.keycloak && param.page > 0) {
      const data = {
        param: param,
        body: body,
        headers: getDefaultHeader(),
      };

      dispatch(MappingAction.AppilcationUrlListProcess(data));
    }
  };
  function handleSearch(event) {
    const inputValue = event.target.value;
    setsearchText(inputValue);

    if (inputValue !== "" && selectedValue === "Explorer" && moduleList) {
      const filteredSuggestions = moduleList.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      setSuggestions(filteredSuggestions);
    } else if (
      inputValue !== "" &&
      selectedValue === "Apps/URLs" &&
      userNodes
    ) {
      const param = {
        page: 1,
        size: 200,
        sortBy: "id",
        direction: "desc",
      };
      const getBodyParam = getBodyParameters("all", inputValue, "ALL");
      GetApplicationUrlList(param, getBodyParam);
    } else if (inputValue !== "" && selectedValue === "Users" && userNodes) {
      const filteredSuggestionUsers = userNodes.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestionUsers(filteredSuggestionUsers);
    } else {
      setSuggestions([]);
      setSuggestionUsers([]);
    }
    // Filter suggestions based on the input value
  }

  useEffect(() => {
    if (applicationurl?.applicationurl_list_success) {
      if (applicationurl?.applicationurl_list_data?.length > 0) {
        const appUrlData = [];
        applicationurl?.applicationurl_list_data.map((item, index) => {
          const data = {
            id: item?.id,
            categoryName:
              item?.application_category === null
                ? "Unmapped"
                : item?.application_category?.name,

            appUrlName: item?.name,
            type:
              item?.type === "APPLICATION"
                ? "Apps"
                : item?.type === "URL"
                ? "URLs"
                : "",
          };

          appUrlData.push(data);
        });
        setAppsUrls(appUrlData);
      }
    }
  }, [
    applicationurl?.applicationurl_list_success,
    applicationurl?.applicationurl_list_data,
    applicationurl?.applicationurl_list_failed,
    searchText,
  ]);

  const handleSelectSuggestion = (selectedItem) => {
    setsearchText(selectedItem.name);
    setSuggestions([]);
    if (selectedItem.key === "settings") {
      navigate("/" + selectedItem.key, {
        state: { id: selectedItem.id, name: selectedItem.name },
      });
    } else if (selectedItem.key === "reports") {
      navigate("/" + selectedItem.key, {
        state: { id: selectedItem.id },
      });
    } else if (selectedItem.key === "helps") {
      window.open(selectedItem.id, "_blank");
    } else {
      navigate("/" + selectedItem.key);
    }
  };

  const handleSelectChange = (value: string) => {
    setsearchText("");
    setSelectedValue(value);
  };
  const selectAfter = (
    <Select
      defaultValue={selectedValue}
      onChange={handleSelectChange}
      style={{ width: 120 }}
      size="large"
      className="serach-common"
    >
      <Select.Option value="Explorer">Explorer</Select.Option>
      <Select.Option value="Users">Users</Select.Option>
      <Select.Option value="Apps/URLs">Apps/URLs</Select.Option>
    </Select>
  );

  const handleSelectUser = (value) => {
    const startDate = dayjs().subtract(6, "d");
    const endDate = dayjs();
    setsearchText(value.title);
    dispatch(
      openUserDetail({ identityId: value.value, tab: 0, startDate, endDate })
    );
    setSuggestionUsers([]);
  };
  const handleSelectAppUrl = (value) => {
    setsearchText(value.appUrlName);
    navigate("/application", {
      state: { id: "Detailed", category: value.categoryName, type: value.type },
    });
    setAppsUrls([]);
  };
  const handleClear = () => {
    setsearchText("");
  };
  return (
    <div className="relative" onMouseLeave={handleClear}>
      {" "}
      <Input
        placeholder={
          selectedValue === "Explorer"
            ? "Discover it all: features, settings, reports..."
            : selectedValue === "Apps/URLs"
            ? "Search any Apps & URLs ..."
            : "Search Users..."
        }
        size="large"
        // style={{
        //   width: 500,
        // }}
        className="header-common-width"
        value={searchText}
        onChange={handleSearch}
        prefix={<SearchOutlined />}
        allowClear
        addonAfter={selectAfter}
      />
      {searchText && selectedValue === "Explorer" ? (
        <ul className="common_search bg-white border-gray-200 shadow min-w-[500px] top-[40px] m-0 p-0 outline-0">
          {suggestions.map((item) => (
            <li
              key={item.id}
              onClick={() => handleSelectSuggestion(item)}
              className="text-[13px] cursor-pointer text-slate-700 list-none py-[15px] flex items-center pl-[20px] border-gray-200 hover:bg-slate-200 hover:text-gray-800"
            >
              {item?.icon && (
                <img src={item?.icon} alt={item?.name} className="mr-3" />
              )}
              <div>
                <strong>{item?.name}</strong>
                <div className="text-gray-500">{item?.description}</div>
              </div>
            </li>
          ))}
        </ul>
      ) : searchText && selectedValue === "Apps/URLs" ? (
        <ul className="common_search bg-white border-gray-200 shadow min-w-[500px] top-[40px] m-0 p-0 outline-0">
          {appsUrls.map((item) => (
            <li
              key={item.id}
              onClick={() => handleSelectAppUrl(item)}
              className="text-[13px] cursor-pointer text-slate-700 list-none py-[15px] flex items-center pl-[20px] border-gray-200 hover:bg-slate-200 hover:text-gray-800"
            >
              {item?.type === "APPLICATION" ? (
                <DesktopOutlined className="mr-3" />
              ) : (
                <GlobalOutlined className="mr-3" />
              )}
              <div>
                <strong>{item?.appUrlName}</strong>
                <div className="text-gray-500">{item?.categoryName}</div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        searchText && (
          <ul className="common_search bg-white border-gray-200 shadow min-w-[500px] top-[40px] m-0 p-0 outline-0">
            {suggestionUsers &&
              suggestionUsers.map((item) => (
                <li
                  key={item.value}
                  onClick={() => handleSelectUser(item)}
                  className="text-[13px] cursor-pointer text-slate-700 list-none py-[15px] flex items-center pl-[20px] border-gray-200 hover:bg-slate-200 hover:text-gray-800"
                >
                  <img src={User} alt={item?.title} className="mr-3" />

                  <div>
                    <strong>{item?.title}</strong>
                    <div className="text-gray-500">{item?.email}</div>
                  </div>
                </li>
              ))}
          </ul>
        )
      )}
    </div>
  );
};
export default Commonsearch;
