import * as actionTypes from "./actionTypes";

const initialPagination = { optional: 0 };

const initialState = {
  fetchIntegrationLogsReportLoading: true,
  IntegrationlogsReportData: [],
  pagination: initialPagination,
  IntegrationlogReportErrorMessage: "",
  downloadIntegrationLogsReportLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_INTEGRATION_LOGS_REPORT:
      return {
        ...state,
        fetchIntegrationLogsReportLoading: true,
        IntegrationlogReportErrorMessage: "",
      };

    case actionTypes.FETCH_INTEGRATION_LOGS_REPORT_SUCCESS: {
      return {
        ...state,
        fetchIntegrationLogsReportLoading: false,
        IntegrationlogsReportData: action.payload.data,
        pagination: action.payload || state.pagination,
      };
    }

    case actionTypes.FETCH_INTEGRATION_LOGS_REPORT_FAILED:
      return {
        ...state,
        fetchIntegrationLogsReportLoading: false,
        IntegrationlogsReportData: [],
        pagination: initialPagination,
        IntegrationlogReportErrorMessage: action.payload,
      };
    case actionTypes.DOWNLOAD_INTEGRATION_LOGS_REPORT:
      return {
        ...state,
        downloadIntegrationLogsReportLoading: true,
        IntegrationlogReportErrorMessage: "",
      };

    case actionTypes.DOWNLOAD_INTEGRATION_LOGS_REPORT_SUCCESS:
      return {
        ...state,
        downloadIntegrationLogsReportLoading: false,
      };

    case actionTypes.DOWNLOAD_INTEGRATION_LOGS_REPORT_FAILED:
      return {
        ...state,
        downloadIntegrationLogsReportLoading: false,
        IntegrationlogReportErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
